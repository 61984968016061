:root{
    --primary: #000000;
    --green: #000000;
}

.btn{
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    
    border-radius: 2px;
    border-color: #000000;
    border-style: solid;
}

.btn--primary{
    background-color: transparent;
}

.btn--outline{
    padding: 8px 20px;
    border-radius: 4px;
    transition: all 0.3s ease-out;
}

.btn--medium{
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 18px;
    color: #000000;
}

.btn--large{
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 20px;
    color: #000000;
}