.card{
    /* Sizing */
    height: 100%;
    width: 100%;

    transform-style: preserve-3d;

    /* Content Alignment */
    position: relative;


    /* Styling */
    border-radius: 5px;
    border: 5px solid white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);

    user-select: none;
    cursor: pointer;
}

.card-front,
.card-back{
    /* Sizing */
    height: 100%;
    width: 100%;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    /* Alignment */
    position: absolute;

    /* Content Alignment */

    /* Content Styling */
    font-family: Montserrat;
    font-weight: 800;
    color: white;
    
}

.card-front{
    background-color: #73d0ff;
}

.card-back{
    background-color: #ffad66;
    transform: rotateY(180deg);
}

h1{
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75%;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.9);
}

p{

    /* Content Styling */
    font-size: 20px;
    line-height: 30px;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.9);
    /* Content Alignment */
    
    text-align: justify;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon1{
    /* Content Alignment */
    font-size: 50px;
    margin: 20px;
    padding-top: 70px;
    text-align: center;
}

.resume{
    font-size: 50px;
    margin: 20px;
    padding-top: 50px;
    text-align: center;
}